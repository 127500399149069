import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4f81eef3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "bills"
};
const _hoisted_2 = {
  class: "title_box",
  style: {
    "margin-bottom": "16px"
  }
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "box",
  style: {
    "margin-bottom": "16px"
  }
};
const _hoisted_5 = {
  class: "left quire"
};
const _hoisted_6 = {
  class: "right quire"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_9 = {
  style: {
    "margin-right": "14px"
  }
};
const _hoisted_10 = {
  class: "line"
};
const _hoisted_11 = {
  class: "li_box"
};
const _hoisted_12 = {
  class: "title"
};
const _hoisted_13 = {
  class: "num"
};
const _hoisted_14 = {
  class: "li_box"
};
const _hoisted_15 = {
  class: "title"
};
const _hoisted_16 = {
  class: "num"
};
const _hoisted_17 = {
  class: "li_box"
};
const _hoisted_18 = {
  class: "title"
};
const _hoisted_19 = {
  class: "num"
};
const _hoisted_20 = {
  class: "li_box"
};
const _hoisted_21 = {
  class: "title"
};
const _hoisted_22 = {
  class: "num"
};
const _hoisted_23 = {
  class: "li_box"
};
const _hoisted_24 = {
  class: "title"
};
const _hoisted_25 = {
  class: "num"
};
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from "vue-i18n";
import { user } from '@/api/index';
import { useUserStore } from '@/store/user';
export default {
  __name: 'details',
  setup(__props) {
    const {
      t
    } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useUserStore();
    let isshow = ref(false);
    let numis = ref(1);
    let titleText = ref(t('lang.kad13'));
    function isshowfun(item) {
      return;
      numis.value++;
      item.isshow = !item.isshow;
    }
    watch(() => store.account, (newValue, oldValue) => {
      console.log("新的值:", newValue);
      console.log("旧的值:", oldValue);
      detailslist.value = [];
      page = 1;
      finished.value = false;
      loading.value = true;
      getminerpublic();
    });
    watch(() => store.Lang, (newValue, oldValue) => {
      detailslist.value = [];
      page = 1;
      finished.value = false;
      loading.value = true;
      getminerpublic();
    });
    onMounted(() => {
      miner_id = route.query.id;
      if (route.query.id == 1) {
        titleText.value = t('lang.kad13');
      } else if (route.query.id == 2) {
        titleText.value = t('lang.kad14');
      } else if (route.query.id == 3) {
        titleText.value = t('lang.kad15');
      }
      getminerpublic();
    });
    let page_size = 10;
    let page = 1;
    let miner_id = 1;
    let detailslist = ref([]);
    function getminerpublic() {
      let data = {
        page_size: page_size,
        page: page,
        miner_id: miner_id
      };
      user.minerpublicity2(data, store.account).then(res => {
        if (res.data.list.length < page_size) {
          finished.value = true;
        }
        if (res.data.list.length != 0) {
          for (let i in res.data.list) {
            res.data.list[i].isshow = false;
          }
        }
        detailslist.value = [...detailslist.value, ...res.data.list];
        console.log(detailslist.value);
      });
    }
    let loading = ref(false);
    let finished = ref(false);
    function onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        page++;
        if (finished.value) return;
        getminerpublic();
        loading.value = false;
      }, 1000);
    }
    return (_ctx, _cache) => {
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(_unref(titleText)), 1)]), _createVNode(_component_van_list, {
        modelValue: _unref(loading),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : loading = $event),
        finished: _unref(finished),
        "finished-text": "暂无更多",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(detailslist), (item, index) => {
          return _openBlock(), _createElementBlock("div", _hoisted_4, [_withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [[_vShow, item.isshow]]), _withDirectives(_createElementVNode("div", _hoisted_6, null, 512), [[_vShow, item.isshow]]), _createElementVNode("div", {
            class: "show_box",
            onClick: $event => isshowfun(item)
          }, [_createElementVNode("span", null, _toDisplayString(_unref(t)('lang.kad11')), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(item.address), 1)])], 8, _hoisted_7), _withDirectives(_createElementVNode("div", _hoisted_10, null, 512), [[_vShow, item.isshow]]), _createElementVNode("div", {
            class: _normalizeClass(item.isshow ? 'nonce_box uppper' : _unref(numis) <= 1 && item.isshow ? 'nonce_box' : 'nonce_box down')
          }, [_createElementVNode("div", _hoisted_11, [_createElementVNode("span", _hoisted_12, _toDisplayString(_unref(t)('lang.kad4')), 1), _createElementVNode("span", _hoisted_13, _toDisplayString(item.self_hold), 1)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("span", _hoisted_15, _toDisplayString(_unref(t)('lang.kad5')), 1), _createElementVNode("span", _hoisted_16, _toDisplayString(item.dr_children), 1)]), _createElementVNode("div", _hoisted_17, [_createElementVNode("span", _hoisted_18, _toDisplayString(_unref(t)('lang.kad6')), 1), _createElementVNode("span", _hoisted_19, _toDisplayString(item.team_hold), 1)]), _createElementVNode("div", _hoisted_20, [_createElementVNode("span", _hoisted_21, _toDisplayString(_unref(t)('lang.kad7')), 1), _createElementVNode("span", _hoisted_22, _toDisplayString(item.min_zone_hold), 1)]), _createElementVNode("div", _hoisted_23, [_createElementVNode("span", _hoisted_24, _toDisplayString(_unref(t)('lang.kad12')), 1), _createElementVNode("span", _hoisted_25, _toDisplayString(item.acc_profit), 1)])], 2)]);
        }), 256))]),
        _: 1
      }, 8, ["modelValue", "finished"])]);
    };
  }
};