import axios from "axios";
import { Toast } from "vant";

const service = axios.create({
   
    baseURL: 'https://ais.kadchain.cc/api/',
  
});


service.interceptors.request.use(
    config => {
	
			
 
		
		config.headers.lang = localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US'
		return config
		
	},
	error => {
		return Promise.reject(error);
	}
);

// ajax响应拦截器;
service.interceptors.response.use(
    (res) => {
        // 获取后端返回的数据与状态码;
        // 状态码位200时, 则无异常;
        if (res.code === 200) {
            return res.data;
        } else{
            return res.data;
        }
    },
    (error) => {
     
        return Promise.reject(error);
    }
);
export default service;