import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-99e4e3ac"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "view"
};
import Header from '@/components/Header.vue';
import { useUserStore } from '@/store/user';
export default {
  __name: 'index',
  setup(__props) {
    const store = useUserStore();
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(Header, {
        style: {
          "z-index": "99999"
        },
        account: _unref(store).account
      }, null, 8, ["account"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view)])]);
    };
  }
};