export const lang ={
    kad1:"檢測我的地址",
    kad2:"進入",
    kad3:"大區持有",
     kad4:"个人持有",
     kad5:"有效礦工",
     kad6:"團隊持有",
     kad7:"小區持有",
    kad8:"帳單",
    kad9:"暫無更多",
    kad10:"獎勵類型",
    kad11:"地址",
    kad12:"已領取總收益",
    kad13:"銅牌地址公佈",
    kad14:"銀牌地址公佈",
    kad15:"金牌地址公佈",
    kad16:"我的礦機",
    kad17:"礦機編號",
    kad18:"已產出數量",
    kad19:"剩餘數量",
    kad20:" 您尚未持有礦機！！！",
    kad21:"可領取",
    kad22:"礦機地址公告",
    kad23:"暫無可領取",
    kad24:"簽名失敗",
    kad25:"簽名成功，正在為您產生訂單...",
    kad26:"訂單建立失敗",
    kad27:"領取",
    kad28:"",
}