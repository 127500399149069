export const lang ={
     kad1:"检测我的地址",
     kad2:"进入",
     kad3:"大区持有",
     kad4:"个人持有",
     kad5:"有效矿工",
     kad6:"团队持有",
     kad7:"小区持有",
     kad8:"账单",
     kad9:"暂无更多",
     kad10:"奖励类型",
     kad11:"地址",
     kad12:"已领取总收益",
     kad13:"铜色NFT地址",
     kad14:"银色NFT地址",
     kad15:"金色NFT地址",
     kad16:"我的NFT",
     kad17:"NFT编号",
     kad18:"已产出数量",
     kad19:"剩余数量",
     kad20:" 您尚未持有NFT！！！",
     kad21:"可领取",
     kad22:"NFT地址公示",
     kad23:"暂无可领取",
     kad24:"签名失败",
     kad25:"签名成功，正在为您生成订单...",
     kad26:"订单创建失败",
     kad27:"领取", 
     kad28:"",
}