// 要想使用pinia必须先引入defineStore  这里我们使用es6的模块化语法导出的
import { defineStore } from 'pinia'
import { user } from '@/api/index'
import Web3 from 'web3'
// defineStore 方法有两个参数，第一个参数是模块化名字（也就相当于身份证一样，不能重复）
// 第二个参数是选项，对象里面有三个属性，相比于vuex 少了一个 mutations
export const useUserStore = defineStore('user', {
  // 定义共享状态
  state() {  //是一个函数返回一个对象，对象里面就是可以共享的状态
    return {
      account: '',
      checkColor: 'white',
      Lang: localStorage.getItem('Lang') || 'en-US',
      network: ''
    }
  },

  // persist: {
  //     enabled: true,
  //     strategies: [
  //       { storage: sessionStorage, paths: ['account', 'Lang'] }, // firstName 和 lastName字段用sessionStorage存储

  //     ],
  //   },

  // getters是一个对象,可以定义类型计算数据的函数,直接用来当变量使用
  // 相当于vue里面的计算属性，可以缓存数据
  getters: {
    fullName() {
      return this.firstName + this.lastName + this.age
    }
  },
  // actions 是一个对象，可以定义方法修改state里面的数据
  // 可以是异步，也可以是同步
  actions: {
    async setWebProvider() {

      var web3Provider;
      const _this = this
      web3Provider = window.ethereum;
      const web3js = new Web3(web3Provider);


      try {

        // 请求用户授权
        await web3Provider.request({
          method: 'eth_requestAccounts',
          params: [
            {
              chainId: '0x38',
              chainName: 'Binance Smart Chain Mainnet',
              nativeCurrency: {
                name: 'BNB',
                symbol: 'bnb',
                decimals: 18,
              },
              rpcUrls: ['https://bsc-dataseed4.defibit.io/'],
              blockExplorerUrls: [`https://bsc-dataseed4.defibit.io/`],
            },
          ],
        });

        await web3Provider.enable();
      } catch (error) {
        // 用户不授权时

        console.error('User denied account access');
      }
      web3js.eth.getAccounts(function (error, result) {

        if (!error) {

      
          if (_this.account != result[0]) {

            _this.account = result[0]

            let data = {
              address: _this.account,

            }
            user.register(data).then(res => {

            })
          }




        }
      });
      web3Provider.on('networkChanged', function (networkIDstring) {

        _this.network = networkIDstring

      });
      web3Provider.on('accountsChanged', function (accounts) {

        _this.account = accounts[0]

        console.log('切换账号', accounts);
      });
    },
    changeLastName(payload) {
      this.lastName = payload
    },
    changeAge(payload) {
      setTimeout(() => {
        this.age = payload
      }, 3000)
    }
  }
})