import request from '../utils/request';
export default {
    //检测考核数据
    minerexamine(account) {
        
        return request.request({
            url: '/miner/examine',
            method: "get",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
          
        })
    },
    register(data,account) {
        
        return request.request({
            url: '/register',
            method: "post",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
          data:data
        })
    },
    minerholdinfo(account) {
        
        return request.request({
            url: '/miner/hold-info',
            method: "get",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
          
        })
    },
    minerpublicity(data,account) {
        
        return request.request({
            url: '/miner/list',
            method: "get",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
          params:data
        })
    },
    minerpublicity2(data,account) {
        
        return request.request({
            url: '/miner/publicity',
            method: "get",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
          params:data
        })
    },
    walletrecordlist(data,account) {
        
        return request.request({
            url: '/wallet/record/list',
            method: "get",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
          params:data
        })
    },
    walletconfig(account) {
        
        return request.request({
            url: '/miner/config',
            method: "get",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
         
        })
    },
    withdrawordercreateorder(data,account) {
        
        return request.request({
            url: '/withdraw/order/create-order',
            method: "post",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
         data:data
        })
    },
    withdraworderwalletout(data,account) {
        
        return request.request({
            url: '/withdraw/order/wallet-out',
            method: "post",
            headers:{
                Authorization:account,
                Lang: localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en-US',
             },
         data:data
        })
    },
}