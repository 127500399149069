import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../views/Home/index.vue'
import fuwongIndex from '../views/fuwong/Index.vue'
import Miners from '../views/Home/miners.vue'
import bill from '../views/Home/bill.vue'
import details from '../views/Home/details.vue'
const routes = [
  {
    path: '/',

    name: 'fuwongIndex',
    component: fuwongIndex,
  },
  
  {
    path: '/home',
    redirect:'Miners',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/Miners',
        name: 'Miners',
        component: Miners,
      },
      {
        path: '/bill',
        name: 'bill',
        component: bill,
      },
      {
        path: '/details',
        name: 'details',
        component: details,
      },
    ]
  },

]

const router = createRouter({
  mode: 'hash',
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
