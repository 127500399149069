import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main"
};
import { ref } from 'vue';
import { onMounted, nextTick, onBeforeMount } from 'vue';
import { useUserStore } from '@/store/user';
import { user } from '@/api/index';
import Web3 from "web3";
const bian = "https://rpchttp.kadsea.ink";
export default {
  __name: 'App',
  setup(__props) {
    const store = useUserStore();
    const account = ref('');
    onMounted(async () => {});
    onBeforeMount(async () => {
      store.setWebProvider();
      console.log('store', store.account);
      // await getaccount()
      if (!localStorage.getItem('Lang')) {
        localStorage.setItem('Lang', 'en-US');
      }
      sessionStorage.setItem('url', bian);
      setInterval(() => {
        store.setWebProvider();
      }, 6000);
    });
    const getaccount = async () => {
      web3.eth.requestAccounts().then(res => {
        let account1 = account.value;
        account.value = res[0];
        console.log(account.value);
        if (account1 != account.value) {
          let data = {
            address: account.value
          };
          user.register(data).then(res => {
            console.log(res);
          });
        }
        if (account1 != account.value || store.account == '' || store.account == undefined) {
          store.account = account.value;
          console.log('222222222', store.account);
        }
      });
      const aaa = await web3.eth.getGasPrice();
      console.log(aaa);
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};