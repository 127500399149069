import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../assets/logo.png';
import _imports_1 from '../assets/img/sun_icon.png';
import _imports_2 from '../assets/img/moom_icon.png';
const _withScopeId = n => (_pushScopeId("data-v-24a2fdf4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "lang"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "h_right"
};
const _hoisted_5 = ["src"];
import { reactive, nextTick } from 'vue';
import { ref, computed, onMounted } from "vue";
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'Header',
  props: {
    account: {
      type: String
    }
  },
  setup(__props) {
    const props = __props;
    const router = useRouter();
    const store = useUserStore();
    const {
      locale: i18nLanguage
    } = useI18n();
    const toggle = item => {
      showlang.value = false;
      store.Lang = item.lang;
      localStorage.setItem('Lang', item.lang);
      currentLanguage.value = item.text;
      currentLanguageLogo.value = item.logo;
      i18nLanguage.value = item.lang;
      // zh en
    };

    const sholangfun = () => {
      showlang.value = false;
    };
    let currentLanguage = ref("中文简体");
    let currentLanguageLogo = ref("");
    const showlang = ref(false);
    let checkisdark = ref(true);
    onMounted(() => {
      actions.forEach((item, index) => {
        if (item.lang == (localStorage.getItem('Lang') || 'en-US')) {
          currentLanguage.value = item.text;
          currentLanguageLogo.value = item.logo;
          console.log('currentLanguageLogo.value', currentLanguageLogo.value);
        }
      });
    });
    const goIndex = () => {
      router.push('/');
    };
    const handleLogin = () => {
      store.setWebProvider();
      window.location.reload();
    };
    const fromaddress = ref('');
    var actions = [{
      text: '中文简体',
      lang: 'zh-CN',
      logo: require('@/assets/img/wangluo.png')
    }, {
      text: 'English',
      lang: 'en-US',
      logo: require('@/assets/img/en.png')
    }, {
      text: '中文繁体',
      lang: 'zh-HK',
      logo: require('@/assets/img/fn.png')
    }];
    const fromcom = computed(() => {
      const head = store.account.slice(0, 6);
      const tail = store.account.slice(store.account.length - 4);
      if (store.account == '') {
        return '请连接账号';
      } else {
        return head + "..." + tail;
      }
    });
    function checkDark() {
      checkisdark.value = !checkisdark.value;
      store.checkColor = store.checkColor == 'black' ? 'white' : 'black';
      document.documentElement.dataset.theme = store.checkColor;
      console.log('document.documentElement.dataset.theme', document.documentElement.dataset.theme);
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", {
        class: "fixed",
        onClick: _cache[0] || (_cache[0] = $event => sholangfun())
      }, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(actions), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          onClick: _withModifiers($event => toggle(item), ["stop"])
        }, _toDisplayString(item.text), 9, _hoisted_3);
      }), 256))])], 512), [[_vShow, showlang.value]]), _createElementVNode("div", {
        class: "h_left"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: "",
        onClick: goIndex,
        style: {
          "width": "36px",
          "height": "36px",
          "margin-left": "12px"
        }
      })]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        onClick: handleLogin,
        style: {
          "margin-right": "12px"
        }
      }, _toDisplayString(fromcom.value), 1), _createElementVNode("img", {
        src: _unref(currentLanguageLogo),
        style: {
          "margin-right": "12px"
        },
        alt: "",
        onClick: _cache[1] || (_cache[1] = $event => showlang.value = !showlang.value)
      }, null, 8, _hoisted_5), _unref(checkisdark) ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: _imports_1,
        alt: "",
        onClick: _cache[2] || (_cache[2] = $event => checkDark())
      })) : (_openBlock(), _createElementBlock("img", {
        key: 1,
        src: _imports_2,
        alt: "",
        onClick: _cache[3] || (_cache[3] = $event => checkDark())
      }))])]);
    };
  }
};